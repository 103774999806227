import { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { pnlClass, getAMCLogo } from "../../services/Utilities";
import API from "../../api";
import SkeletonText from "../../components/SkeletonText";
import {
  waitMessages,
  getFundReturns,
  showSchemeDetails,
  spinnerState,
  fundDetailsState,
  navState,
} from "../../services/FundServices";
import { getChartData } from "../../services/ChartServices";
import moment from "moment";
import {
  FilterBar,
  FundList,
  CategoryList,
  SubCategoryList,
  ExploreSection,
} from "./Components";

import "../../theme/styles.scss";
import "./Funds.scss";

/* code splitting for performance */
import loadable from "@loadable/component";
const Header = loadable(() => import("../../components/Header"));
const Footer = loadable(() => import("../../components/Footer"));
const Section = loadable(() => import("../../components/Section"));
const Sidebar = loadable(() => import("../../components/Sidebar"));
const NavChart = loadable(() => import("../../components/NavChart"));
const Searchbar = loadable(() =>
  import("../../components/Searchbar/Searchbar")
);
const ContentPane = loadable(() => import("../../components/ContentPane"));

function Funds() {
  let timer;
  let { id } = useParams();
  const [funds, setFunds] = useState();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://kashly.in/assets/jsons/funds.js?v=" + Date.now();
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      setFunds(window.autoCompleteData);
    });
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [spinner, setSpinner] = useState(spinnerState);
  const [chartData, setChartData] = useState([]);
  const [returnType, setReturnType] = useState("ABSOLUTE");
  const [showReturns, setShowReturns] = useState(<></>);
  const [fundDetails, setFundDetails] = useState(fundDetailsState);
  const [navHistory, setNAVHistory] = useState(navState);

  useEffect(() => {
    setShowReturns(getFundReturns(spinner.isLoading, returnType, navHistory));
  }, [returnType, navHistory]);

  useEffect(() => {
    setSpinner({
      ...spinner,
      isLoading: true,
    });
    setReturnType("ABSOLUTE");
    if (id > "") {
      API.getFundDetails(id)
        .then((result) => {
          if (result.data.status === "success") {
            let res = result.data.data;
            setFundDetails({
              ...fundDetails,
              fundName: res.schemeName,
              latestNav: res.latestNav,
              latestNavDate: res.latestNavDate,
              previousDayNav: res.previousDayNav,
              previousDayNavDate: res.previousDayNavDate,
              isinCode: res.isinCode,
              category: res.category,
              subCategory: res.subCategory,
              minPurchaseAmt: res.rules.minPurchaseAmt,
              minSipAmt: res.rules.minSipAmt,
              navChange:
                ((res.latestNav - res.previousDayNav) / res.previousDayNav) *
                100,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      /* Get NAV History */
      API.getNAVHistory(id)
        .then((result) => {
          if (result.data.status === "success") {
            let res = result.data.data;
            setNAVHistory({
              ...navHistory,
              absReturns: {
                threeMonths: res.absReturns.threeMonths,
                sixMonths: res.absReturns.sixMonths,
                nineMonths: res.absReturns.nineMonths,
                oneYear: res.absReturns.oneYear,
                threeYears: res.absReturns.threeYears,
                fiveYears: res.absReturns.fiveYears,
              },
              yearlyCAGR: {
                one: res.yearlyCAGR.oneYear,
                three: res.yearlyCAGR.threeYears,
                five: res.yearlyCAGR.fiveYears,
              },
              chartData: res.navHistory,
            });

            var data = [];
            res.navHistory.map((item, key) => {
              data.push({
                date: item.navDate,
                nav: item.nav,
                dispDate: moment(item.navDate, "YYYY-MM-DD").format(
                  "MMM DD, YYYY"
                ),
              });
            });
            setChartData(data);

            setSpinner({
              ...spinner,
              isLoading: false,
            });
            clearInterval(timer);
          }
        })
        .catch((error) => {
          console.log(error);
          setSpinner({
            ...spinner,
            isLoading: false,
          });
        });
    }
  }, [id]);

  const drawChart = (chartData, duration) => {
    if (chartData != null) {
      setChartData(getChartData(chartData, duration));
    }
  };

  useEffect(() => {
    let idx = 0;
    let animationStyle = "text-focus-in";
    if (spinner.isLoading) {
      timer = setInterval(() => {
        if (idx % 2 == 0) {
          animationStyle = "text-focus-in";
        } else {
          animationStyle = "focus-in-expand";
        }
        setSpinner({
          ...spinner,
          animationClass: animationStyle,
          loadingMsg: waitMessages[idx],
        });
        idx++;
        if (idx > 3) {
          idx = 0;
        }
      }, 3000);
    }
  }, []);

  const showFundDetails = () => {
    return (
      <div className="fund-details">
        <Container>
          <div className="shadow-box">
            {spinner.isLoading ? (
              <Row>
                <Col xs={2} lg={1} className="amc-logo">
                  <SkeletonText width="100%" height="60px" />
                </Col>
                <Col xs={10} lg={11}>
                  <SkeletonText width="70%" height="25px" />
                  <SkeletonText width="50%" height="20px" />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={2} lg={1} className="amc-logo">
                  <img src={getAMCLogo(fundDetails.fundName)} />
                </Col>
                <Col xs={10} lg={11}>
                  <h5>{fundDetails.fundName}</h5>
                  <span className="text-s">
                    {fundDetails.category + ", " + fundDetails.subCategory}
                  </span>
                </Col>
              </Row>
            )}
          </div>
          <br />
          <br />
          <Row>
            <Col lg={4}>
              <div className="shadow-box">
                <h6>Latest NAV</h6>
                {spinner.isLoading ? (
                  <>
                    <SkeletonText width="80%" height="30px" />
                    <SkeletonText width="80%" height="20px" />
                  </>
                ) : (
                  <>
                    <h5 className="no-margin no-padding">
                      <span className={pnlClass(fundDetails.navChange)}>
                        {fundDetails.latestNav}{" "}
                        <i className="fa fa-arrow-circle-down"></i>(
                        {Math.round(fundDetails.navChange * 100) / 100}%)
                      </span>
                    </h5>
                    <span className="text-xs">
                      as on {fundDetails.latestNavDate}
                    </span>
                    <br />
                    <br />
                  </>
                )}
                <br />
                <h6>Returns</h6>
                {showReturns}
                <br />
                <div>
                  <input
                    type="radio"
                    id="cagr-returns"
                    name="return-type"
                    value="CAGR"
                    checked={returnType === "CAGR" ? true : false}
                    onClick={(e) => setReturnType(e.target.value)}
                  />
                  &nbsp;CAGR
                </div>
                <div>
                  <input
                    type="radio"
                    id="abs-returns"
                    name="return-type"
                    value="ABSOLUTE"
                    checked={returnType === "ABSOLUTE" ? true : false}
                    onClick={(e) => setReturnType(e.target.value)}
                  />
                  &nbsp;Absolute Return
                </div>
              </div>
            </Col>
            <Col lg={8}>
              {spinner.isLoading ? (
                <div className="shadow-box" style={{ minHeight: "535px" }}>
                  <div
                    style={{
                      minHeight: "310px",
                      color: "#666",
                      fontSize: "1.5rem",
                      padding: "100px 2rem",
                      textAlign: "center",
                    }}
                  >
                    <span className={spinner.animationClass}>
                      {spinner.loadingMsg}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="shadow-box">
                    <Row>
                      <Col>
                        <NavChart data={chartData} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{ margin: "20px 0" }}>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 1);
                            }}
                          >
                            <span className="text-xs">1M</span>
                          </Button>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 3);
                            }}
                          >
                            <span className="text-xs">3M</span>
                          </Button>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 6);
                            }}
                          >
                            <span className="text-xs">6M</span>
                          </Button>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 12);
                            }}
                          >
                            <span className="text-xs">1Y</span>
                          </Button>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 36);
                            }}
                          >
                            <span className="text-xs">3Y</span>
                          </Button>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 60);
                            }}
                          >
                            <span className="text-xs">5Y</span>
                          </Button>
                          <Button
                            className="graph-button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                              drawChart(navHistory.chartData, 999);
                            }}
                          >
                            <span className="text-xs">All</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="shadow-box">
                    {showSchemeDetails(fundDetails)}
                  </div>
                </>
              )}
            </Col>
          </Row>
          <br />
          <Row></Row>
        </Container>
      </div>
    );
  };

  /* Explore Funds Logic */

  const [userFilter, setUserFilter] = useState({
    category: "",
    subCategory: "",
    amc: "",
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (typeof funds != "undefined") {
      setCategories([...new Set(funds.map((fund) => fund.category))]);
    }
  }, [funds]);

  useEffect(() => {
    if (typeof funds != "undefined") {
      let categoryFunds = [];
      funds.map((fund) => {
        if (fund.category == userFilter.category) {
          categoryFunds.push(fund);
        }
      });
      setSubCategories([
        ...new Set(categoryFunds.map((fund) => fund.sub_category)),
      ]);
    }
  }, [userFilter]);

  const showExploreFunds = () => {
    return (
      <Container>
        <Row>
          <Sidebar>
            <CategoryList
              filter={userFilter}
              setFilter={setUserFilter}
              categories={categories}
            />
            <SubCategoryList
              filter={userFilter}
              setFilter={setUserFilter}
              subCategories={subCategories}
            />
          </Sidebar>
          <ContentPane>
            {userFilter.category == "" ? (
              <ExploreSection filter={userFilter} setFilter={setUserFilter} />
            ) : (
              <FundList funds={funds} filter={userFilter} />
            )}
          </ContentPane>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <Header page="funds" />
      <Section variant="grey">
        <Container>
          {id == null ? (
            <FilterBar filter={userFilter} setFilter={setUserFilter} />
          ) : (
            <></>
          )}
          <Row>
            <Col>
              <Searchbar />
            </Col>
          </Row>
        </Container>

        {id != null ? showFundDetails() : showExploreFunds()}
      </Section>
      <Footer />
    </>
  );
}

export default Funds;
