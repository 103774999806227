import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Home,
  About,
  Funds,
  Contact,
  OpenAccount,
  Application,
  Verification,
  Terms,
  Privacy,
  Disclosure,
  Calculators,
  GoalCalculator,
  SIPCalculator,
  SWPCalculator,
  InflationCalculator,
  RiskProfile,
  Nfo,
  Refund,
  NRIAccount,
} from "./pages";

const useAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

function App() {
  useEffect(() => {
    ReactGA.initialize("G-Z5P1TT0SL8");
  }, []);

  useAnalytics();

  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/open-account" element={<OpenAccount />} />
        <Route path="/nri-account" element={<NRIAccount />} />
        <Route path="/funds">
          <Route index element={<Funds />} />
          <Route path=":id" element={<Funds />} />
        </Route>
        <Route path="/application">
          <Route index element={<Application />} />
          <Route path=":id" element={<Application />} />
        </Route>
        <Route path="/calculators" element={<Calculators />} />
        <Route path="/goal-calculator" element={<GoalCalculator />} />
        <Route path="/sip-calculator" element={<SIPCalculator />} />
        <Route path="/swp-calculator" element={<SWPCalculator />} />
        <Route path="/inflation-calculator" element={<InflationCalculator />} />
        <Route path="/nfo/:id" element={<Nfo />} />
        <Route path="/nfo" element={<Nfo />} />
        <Route path="/verify" element={<Verification />} />
        <Route path="/disclosure" element={<Disclosure />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/risk-profile" element={<RiskProfile />} />
      </Routes>
  );
}

export default App;
