import axios from "axios";

const axiosRequest = (contentType) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {
      "Content-Type": contentType,
    },
  });
  return instance;
};

export default {
  getFundDetails: (id) =>
    axiosRequest("application/json")({
      method: "GET",
      url: "v2/funds/" + id,
    }),

  getNAVHistory: (id) =>
    axiosRequest("application/json")({
      method: "GET",
      url: "v2/funds/nav/" + id,
    }),

  /* new fund offers */

  getActiveNfos: () =>
    axiosRequest("application/json")({
      method: "GET",
      url: "v1/nfo/active",
    }),
  getRecentlyClosedNfos: (count) =>
    axiosRequest("application/json")({
      method: "GET",
      url: "v1/nfo/expired/" + count,
    }),

  /* online application */

  createApplication: (data) =>
    axiosRequest("application/json")({
      method: "POST",
      url: `v2/applications`,
      data: data,
    }),

  verifyApplication: (token) =>
    axiosRequest("application/json")({
      method: "GET",
      url: `v2/applications/verify?token=${token}`,
    }),

  getApplication: (email) =>
    axiosRequest("application/json")({
      method: "GET",
      url: `v2/applications/find?email=${email}`,
    }),

  updateApplication: (id, data) =>
    axiosRequest("application/json")({
      method: "PUT",
      url: `v2/applications/${id}`,
      data: data,
    }),

  uploadApplicationFile: (id, data) =>
    axiosRequest("multipart/form-data")({
      method: "PUT",
      url: `v2/applications/${id}`,
      data: data,
    }),

  /* external integrations */
  getUserIP: () =>
    axiosRequest("application/json")({
      method: "GET",
      url: `https://api.ipify.org/?format=json`,
    }),

  getIFSCDetails: (code) =>
    axiosRequest("application/json")({
      method: "GET",
      url: `https://ifsc.razorpay.com/${code}`,
    }),
};
