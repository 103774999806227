import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import ReactGA from "react-ga4";

import API from "../../api";

import { mapStateToAPI } from "./utils";
import { holdingTypeOptions, taxStatusOptions } from "./options";
import { HOLDING_FORM } from "../constants";
import { validateStarterForm } from "../validations";

import Field from "./Field";
import SectionHeader from "./SectionHeader";

const GetStart = ({ application, setApplication }) => {
  const holderDetails = application.holderDetails;
  const [errorMessage, setErrorMesage] = useState("");
  const [data, setData] = useState({
    taxStatus: "",
    type: "",
    category: "I",
    contact: "",
  });

  useEffect(() => {
    if (holderDetails.category != "") {
      let newState;
      newState = {
        taxStatus: holderDetails.taxStatus ? holderDetails.taxStatus : "",
        type: holderDetails.type ? holderDetails.type : "",
        category: "I",
        contact: application.contact ? application.contact : "",
      };
      setData(newState);
    }
  }, [application]);

  const handleContinue = () => {
    let validationError = validateStarterForm(data);
    if (validationError == "") {
      let newState = {
        ...application,
        form: HOLDING_FORM,
        contact: data.contact,
        holderDetails: {
          ...application.holderDetails,
          taxStatus: data.taxStatus,
          type: data.type,
          category: data.category,
        },
      };
      setApplication(newState);
      modifyApplication(newState);

      ReactGA.event({
        category: "Application",
        action: "Basic Details Completed",
      });
    }
    setErrorMesage(validationError);
  };

  const showErrorMessage = () => {
    return errorMessage !== "" ? (
      <Alert variant="danger">{errorMessage}</Alert>
    ) : null;
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const modifyApplication = (state) => {
    API.updateApplication(application.id, mapStateToAPI(state))
      .then((result) => {})
      .catch((error) => {});
  };

  return (
    <Container className="application-wrapper">
      <SectionHeader
        step="1"
        heading="Hello, Let's get started!"
        subheading="First you need to choose your investment mode and tax status to begin with. If you have any queries related to filling our online application, please write to support@kashly.in we will surely help you."
      />
      <div id="getstart">
        <Row>
          <Col lg={6} sm={12}>
            <Field
              type="select"
              name="type"
              label="Holding Account"
              options={holdingTypeOptions()}
              value={data.type ? data.type : ""}
              onChange={(e) => handleFieldChange(e)}
            />
          </Col>
          <Col lg={6} sm={12}>
            <Field
              type="select"
              name="taxStatus"
              label="Tax Status"
              options={taxStatusOptions()}
              value={data.taxStatus ? data.taxStatus : ""}
              onChange={(e) => handleFieldChange(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Field
              type="text"
              name="contact"
              placeholder="Include country code"
              label="Whatsapp / Communication Number"
              value={data.contact ? data.contact : ""}
              onChange={(event) => handleFieldChange(event)}
            />
          </Col>
          <Col lg={6} sm={12}></Col>
        </Row>
        <br />
        <Row>
          <Col>
            {showErrorMessage()}
            <Button onClick={handleContinue}>Continue</Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default GetStart;
