import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  Row,
  Col,
  Form,
  Alert,
  Container,
  InputGroup,
  Button,
  FormControl,
} from "react-bootstrap";
import Field from "./Field";
import {
  countryOptions,
  occupationOptions,
  incomeOptions,
  pepOptions,
  taxResOptions,
  identityOptions,
} from "./options";
import SectionHeader from "./SectionHeader";
import { mapStateToAPI } from "./utils";
import { validateHolderForm } from "../validations";
import { BANK_FORM, GET_START } from "../constants";
import API from "../../api";

const Holder = ({ application, setApplication }) => {
  const holderRecords = application.holderDetails.holderRecord;
  const type = application.holderDetails.type;
  const [errorMessage, setErrorMesage] = useState("");

  const [holders, setHolders] = useState([
    {
      type: "PR",
      name: "",
      dob: "",
      pan: "",
      relation: "",
      proof: "",
      phone: "",
      email: "",
      income: "",
      occupation: "",
      pepStatus: "",
      birthCity: "",
      birthCountry: "101",
      citizenship: "101",
      nationality: "101",
      taxResidency: "N",
      taxCountry: "",
      taxReference: "",
      identityType: "",
    },
  ]);

  useEffect(() => {
    let newState = [];
    if (holderRecords.length > 0) {
      holderRecords.map((holder) => {
        let object = {
          type: holder.type,
          name: holder.name,
          dob: holder.dob,
          pan: holder.pan,
          relation: holder.relation,
          proof: holder.proof,
          phone: holder.phone,
          email: holder.email,
          income: holder.income,
          occupation: holder.occupation,
          pepStatus: holder.pepStatus,
          birthCity: holder.birthCity,
          birthCountry: holder.birthCountry,
          citizenship: holder.citizenship,
          nationality: holder.nationality,
          taxResidency: holder.taxResidency,
          taxCountry: holder.taxCountry,
          taxReference: holder.taxReference,
          identityType: holder.identityType,
        };
        newState.push(object);
      });
      setHolders(newState);
    }
  }, [application]);

  const handleFieldChange = (index, event) => {
    let data = [...holders];
    data[index][event.target.name] = event.target.value;
    if (event.target.name == "taxResidency") {
      data[index]["taxCountry"] = "";
      data[index]["identityType"] = "";
      data[index]["taxReference"] = "";
    }
    setHolders(data);
  };

  const handleContinue = () => {
    let validationError = validateHolderForm(holders, type);
    if (validationError == "") {
      let newState = {
        ...application,
        form: BANK_FORM,
        holderDetails: {
          type: application.holderDetails.type,
          taxStatus: application.holderDetails.taxStatus,
          category: application.holderDetails.category,
          holderRecord: holders,
        },
      };
      setApplication(newState);
      modifyApplication(newState);

      ReactGA.event({
        category: "Application",
        action: "Holder Details Completed",
      });
    }
    setErrorMesage(validationError);
  };

  const modifyApplication = (state) => {
    API.updateApplication(application.id, mapStateToAPI(state))
      .then((result) => {})
      .catch((error) => {});
  };

  const showErrorMessage = () => {
    return errorMessage !== "" ? (
      <Alert variant="danger">{errorMessage}</Alert>
    ) : null;
  };

  const showHolderEditForm = (holders) => {
    let holderForm = [];
    holders.map((holder, index) => {
      holderForm.push(
        <>
          <Row>
            <Col lg={6} sm={12}>
              <Field
                type="text"
                name="name"
                label="Full name (as per PAN card)"
                value={holder ? holder.name : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={6} sm={12}>
              <Field
                type="text"
                name="pan"
                label="PAN"
                value={holder ? holder.pan : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Form.Label className="required-field">Contact number (Indian)</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text style={{}}>+91</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="phone"
                  type="number"
                  value={holder ? holder.phone : ""}
                  onChange={(event) => handleFieldChange(index, event)}
                />
              </InputGroup>
            </Col>
            <Col lg={6} sm={12}>
              <Field
                type="email"
                name="email"
                label="Email"
                value={holder ? holder.email : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
          <br />
          <hr />
          <SectionHeader
            heading="KYC Details"
            subheading="Details are mandatory for account opening with mutual fund companies."
          />
          <Row>
            <Col lg={4} sm={12}>
              <Field
                type="date"
                name="dob"
                label="Date of birth"
                value={holder ? holder.dob : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={4} sm={12}>
              <Field
                type="text"
                name="birthCity"
                label="City of birth"
                value={holder ? holder.birthCity : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="birthCountry"
                label="Country of birth"
                options={countryOptions()}
                value={holder ? holder.birthCountry : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="citizenship"
                label="Citizenship"
                options={countryOptions()}
                value={holder ? holder.citizenship : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="nationality"
                label="Nationality"
                options={countryOptions()}
                value={holder ? holder.nationality : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="pepStatus"
                label="Politically Exposed Person (PEP)"
                options={pepOptions()}
                value={holder ? holder.pepStatus : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="occupation"
                label="Occupation"
                options={occupationOptions()}
                value={holder ? holder.occupation : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="income"
                label="Gross annual income"
                options={incomeOptions()}
                value={holder ? holder.income : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
            <Col lg={4} sm={12}>
              <Field
                type="select"
                name="taxResidency"
                label="Tax residency in other country? "
                options={taxResOptions()}
                value={holder ? holder.taxResidency : ""}
                onChange={(event) => handleFieldChange(index, event)}
              />
            </Col>
          </Row>
          {holder.taxResidency == "Y" ? (
            <>
              <br />
              <hr />
              <SectionHeader
                heading="Additional Tax Details"
                subheading="Provide additional tax details incase holder is a tax resident other than India."
              />
              <Row>
                <Col lg={4} sm={12}>
                  <Field
                    type="select"
                    name="taxCountry"
                    label="Tax country"
                    options={countryOptions()}
                    value={holder ? holder.taxCountry : ""}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
                <Col lg={4} sm={12}>
                  <Field
                    type="select"
                    name="identityType"
                    label="Identity type"
                    options={identityOptions()}
                    value={holder ? holder.identityType : ""}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
                <Col lg={4} sm={12}>
                  <Field
                    type="text"
                    name="taxReference"
                    label="Tax reference"
                    value={holder ? holder.taxReference : ""}
                    onChange={(event) => handleFieldChange(index, event)}
                  />
                </Col>
              </Row>
            </>
          ) : null}
        </>
      );
    });

    return holderForm;
  };

  const handleBack = () => {
    let newState = {
      ...application,
      form: GET_START,
    };
    setApplication(newState);
  };

  return (
    <Container className="application-wrapper">
      <SectionHeader
        step="2"
        heading="Holder Details"
        subheading="Primary holder is the main investor who can avail the tax benefits under section 80c."
      />
      <Row>
        <Col>
          {showHolderEditForm(holders)}
          {showErrorMessage()}
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={handleContinue}>Continue</Button>
        </Col>
      </Row>
    </Container>
  );
};
export default Holder;
