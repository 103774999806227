import moment from "moment";

export const getChartData = (chartData, duration) => {
  let data = [];
  if (chartData != null) {
    chartData.map((item) => {
      let navDate = item.navDate;
      let navValue = item.nav;
      let navDispDate = moment(navDate, "YYYY-MM-DD").format("MMM DD, YYYY");
      if (duration <= 6) {
        navDispDate = moment(navDate, "YYYY-MM-DD").format("MMM DD");
      }
      if (duration >= 60) {
        navDispDate = moment(navDate, "YYYY-MM-DD").format("MMM YYYY");
      }

      if (
        moment(item.navDate, "YYYY-MM-DD") >
        moment().subtract(duration, "months")
      ) {
        data.push({
          nav: navValue,
          date: navDate,
          dispDate: navDispDate,
        });
      }
    });
    return data;
  }
};
